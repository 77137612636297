
import { defineComponent } from 'vue';
const __default__ = defineComponent({
  name : 'Button',
  props:{
    type:{
      type:String,
      default:"primary"
    },
    bgColor:{
      type:String,
      default: '#8134ff'
    },
    txtColor:{
      type:String,
      default: 'white'
    },
    borderColor:{
      type:String,
      default: '#EAEAEA'
    },
    addIcon:{
      type:Boolean,
      default: false
    },
    disabled:{
      type: Boolean,
      default: false
    },
    modelValue:String,
  },
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "76caf546": (_ctx.bgColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__